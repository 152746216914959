import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';
import { VariablesService } from '../variables/variables.service';
import { DialogService } from '../../components/dialog/service/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterService implements HttpInterceptor {
  constructor(
    public injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,
    public _transferState: TransferState,
    private dialogService: DialogService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Clone the request to add the new header.
    const services = {
      storage: this.injector.get(StorageService),
      util: this.injector.get(UtilService),
      varaibles: this.injector.get(VariablesService)
    };
    const token = services.util.getToken();
    const isToken = services.util.isToken;
    let authReq: HttpRequest<any>;
    if (isToken && !req.params.get('filter')) {
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    } else {
      authReq = req.clone({});
    }
    /**Get the URL of the page from where the services get requested */
    if (isPlatformServer(this.platformId)) {
      const serverReq = this.injector.get(REQUEST);
      services.util.domain_details = {
        domain_name: `${serverReq.get('X-Custom-Header')}`,
        domain_url: `${this.getProtocol(serverReq)}://${serverReq.get('X-Custom-Header')}`,
        full_url: `${this.getProtocol(serverReq)}://${serverReq.get('X-Custom-Header')}${serverReq.url}`
      };
      if (services.util.domain_details.domain_name) {
        services.util.setDomainDetails();
      }
      const storedResponse: string = this._transferState.get(makeStateKey(req.url), null as any);
      if (storedResponse) {
        const response = new HttpResponse({ body: storedResponse, status: 200 });
        return of(response);
      }
    }

    // send the newly created request
    return this.nextHandler(authReq, next, services);
  }

  /**After the api call request handler */
  nextHandler(req: HttpRequest<any>, next: HttpHandler, services): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let postBody: any;
        try {
          postBody = JSON.parse(req.body)
        } catch (error) { }
        const param = req.params.get('showError') || postBody?.showError;
        if (error?.error?.message && param !== 'false' && error.error.error !== 'token_expired') {
          services.util.openSnackBar(error.error.message, 'error');
        }
        if (error?.error?.error === 'token_expired') {
          console.log(error.error.error);
          this.removeUserFromStorage(services);
        }

        if (error && error.status === 403 && error.error.message === 'Access Denied') {
          this.removeUserFromStorage(services);
          services.varaibles.router.navigate(['/new/access-denied']);
          this.dialogService.closeAll();
        }
        // return the error to the method that called it
        return throwError(error);
      }) as any
    );
  }
  /**Get the protocol the requested url from the server */
  getProtocol(req) {
    let proto = req.connection.encrypted ? 'https' : 'http';
    // only do this if you trust the proxy
    proto = req.headers['x-forwarded-proto'] || proto;
    return proto.split(/\s*,\s*/)[0];
  }

  removeUserFromStorage(services) {
    services.storage.delete('user');
    services.storage.delete('userdata');
    services.storage.deleteFromSession('showBanner');
    services.util.isToken = false;
    services.util.isLoggedIn.next(false);
    services.varaibles.userData.next(null);
  }
}
