import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UtilService } from '../util/util.service';
import { VariablesService } from '../variables/variables.service';
import { EventsService } from '../events/events.service';
import { IUser } from '../../models';


// declare var window: any;
/**
 * Authentication service have all the login, sigup related methods
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public APIURL = environment.APP.BASE_URL;
  public CORE_API = environment.APP.CORE_API;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    public http: HttpClient,
    private util: UtilService,
    private vars: VariablesService,
    private events: EventsService
  ) { }

  sessionCheck() {
    const url = this.CORE_API + environment.APP.API_URLS.SESSION_CHECK;
    return this.http.get(url, this.httpOptions);
  }

  /**
   * Login API call method
   * @param data
   */
  login(data) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.LOGIN;
    return this.http.post(url, JSON.stringify(data), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Social Login API call method
   * @param data face user access token
   * @param socialType socialLogin url : Example => LOGIN_FACEBOOK/LOGIN_GOOGLE. Maps to Login url given in environment file
   */
  loginSocail(data, socialType?) {
    this.util.isToken = false;
    const url = socialType ?
      this.APIURL + environment.APP.API_URLS[socialType] :
      this.APIURL + environment.APP.API_URLS.LOGIN_FACEBOOK;
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          'code': data
        }
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Register API call method
   * @param data
   */
  register(data, hideError?, otpStep1?) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.REGISTER;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: { showError: 'false' }
    };
    const httpOptions = hideError ? options : this.httpOptions;
    if (otpStep1) {
      httpOptions['params'].signup_with_otp = true;
    } else {
      if (this.vars.req_nonce) {
        data.request_id = this.vars.req_nonce.toString();
      }
    }
    return this.http.post(url, JSON.stringify(data), httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        this.vars.req_nonce = '';
        return res;
      })
    );
  }
  /**Verify number/email before subiting to get OTP
   * from firebase
   */
  verifyUserDetails(data) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.USER_DETAILS_VERIFY;
    return this.http.post(url, JSON.stringify(data), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Login OTP verification
   */
  loginOTPVerify(data) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.LOGIN_OTP_VERIFY;
    return this.http.post(url, JSON.stringify(data), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Login with OPT
   */
  loginOTP(data) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.LOGIN_OTP;
    return this.http.post(url, JSON.stringify(data), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Forgot password
   * @param email
   */
  forgotPassword(email) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.FORGOT_PASSWORD;
    return this.http.post(url, JSON.stringify(email), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**
   * Rest password
   * @param data Data reqired to reset password
   */
  resetPassword(data) {
    this.util.isToken = false;
    const url = this.APIURL + environment.APP.API_URLS.LOGIN_OTP;
    return this.http.post(url, JSON.stringify(data), this.httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }

  /**
   * Login via UAuth
   */
  loginViaUAuth(U_AUTH) {
    this.util.setUser({ token: U_AUTH });
    return this.getUserProfile();
  }

  /**
   * Get the user details
   */
  getUserProfile() {
    this.util.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.GET_USER_PROFILE + '?with=avtar;isHospital;listsubscriptions;aggdonationall;activateReward;allActiveCampaigns;lastorder;panNumber;subscriptions;activeSubscription.subscriptionLog;healthDetails;latestUserPolicyDetails;lastHealthFirstOrder.orderPlan';
    return this.http.get(url);
  }

  registerUser(data) {
    this.util.isToken = true;
    const url = this.APIURL + environment.APP.API_URLS.REGISTER_USER();
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }
  /**
   * Get the API of the client machin
   */
  getClientIP() {
    const url = this.APIURL + environment.APP.GET_IP;
    return this.http.get(url, this.httpOptions);
  }
  /**
   * Log out from the website
   * @param data token of the current user
   */
  logout(data) {
    const url = this.APIURL + environment.APP.API_URLS.LOGOUT;
    return this.http.post(url + '?token=' + data, {}, this.httpOptions);
  }

  /**
   * Enable/Disable the notifications settings
   * of user like email, sms
   * @param data Required data
   * @param userId Logged in user if
   */
  pushNotifications(data, userId) {
    const url = this.APIURL + environment.APP.API_URLS.PUSH_NOTIFICATIONS(userId);
    return this.http.post(url, JSON.stringify(data), this.httpOptions);
  }

  loginEmailCheck(token, socialType) {
    this.util.isToken = false;
    const url = socialType === 'facebook' ?
      this.APIURL + environment.APP.API_URLS.LOGIN_FACEBOOK :
      this.APIURL + environment.APP.API_URLS.LOGIN_GOOGLE;

    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          'code': token,
          'user_exists': 'true'
        }
      })
    };
    return this.http.get(url, httpOptions).pipe(
      map(res => {
        this.util.isToken = true;
        return res;
      })
    );
  }
  /**Auto login by email */
  autoLogin(data) {
    this.util.isToken = false;
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: { showError: 'false' }
    };
    const url = this.APIURL + environment.APP.API_URLS.AUTO_LOGIN;
    return this.http.post(url, JSON.stringify(data), options);
  }

  /**remove the extention from number present in url */
  removeExtFromNumber(phone: string) {
    if (phone) {
      return phone.length >= 10 ? phone.slice(-10) : phone;
    }
  }

  /**
   * Check if user is exist in our sytem or not
   */
  checkUserIsValid(emailId) {
    const url = this.APIURL + environment.APP.API_URLS.CHECK_USER_IS_VALID(emailId);
    return this.http.get(url, this.httpOptions);
  }

  trueCallerAuth(req_nonce) {
    // const req_nonce = 123456789598;  // random number of length 8 to 64 characters
    window.location.href = `truecallersdk://truesdk/web_verify?requestNonce=${req_nonce}&type=btmsheet&partnerKey=${environment.truecaller.partnerKey}&partnerName=Ketto&lang=en&title=register&skipOption=useanothernum&ctaColor=%2303bfbd&ctaTextColor=%23000000`;
  }

  /**User login with email */
  userLoginWithEmail(data?): Promise<IUser> {
    return new Promise((resolve, reject) => {
      try {
        if (!this.vars.isVariableLogin) {
          const userS = this.util.getUser();
          const loggedIn = userS?.user?.isLoggedIn;

          if (Object.keys(data).filter(k => k === 'em' || k === 'email').length && (!loggedIn || data?.utm_source?.match('internal_impact_calling|internal_stories_otd_calling'))) {

            if (userS) {
              this.util.storage.delete('user');
              this.util.storage.delete('userdata');
              this.util.cookie.deleteCookie('u_auth', '.ketto.org');
              this.util.isToken = false;
              this.util.storage.delete('abandonedCart');
              this.util.storage.delete('abandonedCartOtd');
              this.util.storage.deleteFromSession('showBanner');
              this.util.storage.deleteFromSession('sipUserRedirect');
            }

            const payload = {
              email: data.em || data.email,
              name: data.name || '',
              phone: this.removeExtFromNumber(data.phone) || '',
              extension: ''
            };
            this.autoLogin(payload).subscribe(async (res: any) => {
              try {
                const user: IUser = await this.userLogin(res.data);
                if (data.redirect) {
                  this.util.navigate(data.to || '/');
                }
                resolve(user);
              } catch (error) {
                reject();
              }
            }, (er) => reject(er));
          } else if (loggedIn) {
            const userdata = this.util.storage.get('userdata');
            if (data.redirect) {
              this.util.navigate(data.to || '/');
            }
            resolve(userdata);
          } else {
            reject();
          }
        } else {
          const loginPayload = {
            email: data?.em || data?.email || '',
            name: data?.name || '',
            phone: this.removeExtFromNumber(data?.phone) || '',
            extension: ''
          };
          if (loginPayload.email) {
            this.autoLogin(loginPayload).subscribe({
              next: async (res: any) => {
                try {
                  const user: IUser = await this.userLogin(res.data);
                  if (data?.redirect) {
                    this.util.navigate(data?.to || '/');
                  }
                  resolve(user);
                } catch (error) {
                  reject();
                }
              },
              error: (er) => { reject(er); }
            });
          } else {
            resolve(null);
          }
        }
      } catch (error) {
        reject(false);
      }
    });
  }

  /**User login promise */
  userLogin(res): Promise<IUser> {
    return new Promise((resolve, reject) => {
      try {
        const loginRes = {
          token: res.token,
          user: res.user
        };

        loginRes.user.isLoggedIn = res.login;
        this.util.setUser(loginRes);

        // Get User profile
        this.getUserProfile().subscribe((userRes: any) => {
          let user = userRes.data;
          user['extension'] = user?.extension || this.vars?.userPhoneExt || '';
          user['phone_1'] = user?.phone_1 || this.vars?.userPhoneNumber || '1111111111';
          this.eventsAfterLogin(user);
          if (user.lastorder) {
            user = this.setLastOrderInfo(user);
          }
          this.util.setUserData(user);
          this.vars.userData.next(user);
          this.util.isLoggedIn.next(res.login);
          resolve(user);
        }, (er) => reject(er));
      } catch (error) {
        reject(false);
      }
    });
  }

  /** Set last order of user inside user object */
  setLastOrderInfo(user) {
    const order = user.lastorder;
    if (order) {
      let info = {
        mode_value: order.payment_mode_value,
        mode: order.payment_mode,
        gateway: order.payment_gateway,
        ...order
      };
      if (order.payment_mode && order.payment_mode.match('UPI')) {
        info.upi_id = order.checksum;
      }
      if (order.checksum && order.payment_gateway && order.payment_gateway.match('stripe')) {
        try {
          const stripeChecksum = JSON.parse(order.checksum);
          if (stripeChecksum) {
            this.vars.stripeUsToken = stripeChecksum.token_1;
            info = Object.assign(info, stripeChecksum);
          }
        } catch (error) {
        }
      }
      // this.vars.lastOrderInfo.next(info);
      user.lastorder = info;
      return user;
    } else {
      return user;
    }
  }

  eventsAfterLogin(user) {
    this.events.claverProfilePush({
      'Site': {
        'Identity': user.id,
        'Email': user.email,
        'Name': user.full_name,
        'Phone': user.extension + user.phone_1,
        'Mobile': user.extension + user.phone_1,
        'Full Name For URL': encodeURI(user.full_name),
        'Login_Via': this.vars.loginMethod,
        country_code: user?.extension || ''
      }
    });
  }

  verifyURI(redirect_uri?: string) {
    // const domainRegex = /^(dev[1-9][.]|)+ketto[.]org+$/;
    if (redirect_uri.startsWith('/')) {
      return true;
    }
    const domainRegex = /^(([a-zA-Z0-9_-]+[.])|)+ketto[.]org+$/;
    const REDIRECT_URI = redirect_uri || this.util.actRoute.snapshot.queryParams['redirect_uri'];
    const REDIRECT_DOMAIN = (new URL(REDIRECT_URI)).hostname.replace('www.', '');
    return domainRegex.test(REDIRECT_DOMAIN);
  }

  logoutUser() {
    this.util.storage.delete('user');
    this.util.storage.delete('userdata');
    this.util.cookie.deleteCookie('u_auth', '.ketto.org');
    this.util.isToken = false;
  }
}
