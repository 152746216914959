import { Injectable } from '@angular/core';

declare let bulk_donation_hf_mini_AB;
declare let desktop_tip_AB;
declare let slab_AB;
declare let kcart_tip_story_AB; // (vwo.kcart_tip_story_AB() && vars.showKCart) remove in tip comp after result
declare let kcart_slab_AB;
declare let kcart_not_preselected_AB;

@Injectable({
  providedIn: 'root'
})
export class ABService {

  bulk_donation_hf_mini_AB() {
    if (typeof bulk_donation_hf_mini_AB !== 'undefined') {
      return bulk_donation_hf_mini_AB;
    }
  }

  desktop_tip_AB() {
    if (typeof desktop_tip_AB !== 'undefined') {
      return desktop_tip_AB;
    }
  }

  slab_AB() {
    if (typeof slab_AB !== 'undefined') {
      return slab_AB;
    }
  }

  kcart_tip_story_AB() {
    if (typeof kcart_tip_story_AB !== 'undefined') {
      return kcart_tip_story_AB;
    }
  }

  kcart_slab_AB() {
    if (typeof kcart_slab_AB !== 'undefined') {
      return kcart_slab_AB;
    }
  }

  kcart_not_preselected_AB() {
    if (typeof kcart_not_preselected_AB !== 'undefined') {
      return kcart_not_preselected_AB;
    }
  }

}
