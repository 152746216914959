import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as utc from 'dayjs/plugin/utc';


@Pipe({
  name: 'dayjs'
})
export class DayjsPipe implements PipeTransform {

  transform(value: string, ...args: string[]): any {
    dayjs.extend(advancedFormat);
    switch (args[0]) {
      case 'format': return dayjs(value).format(args[1]);

      case 'diffInDays':
        const diff = dayjs(value).diff(new Date(), 'day');
        return diff > 0 ? diff.toString() : 0;

      case 'diffInMonths':
        return dayjs(value).diff(args[1], 'month');

      case 'to':
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        return dayjs().to(dayjs.utc(value));

      case 'isAfter':
        return dayjs(value).isAfter(dayjs(args[1]));

      case 'diffInPreviousDays':
        return dayjs(new Date()).diff(value, 'day');

      case 'addMonth': return dayjs(value).add(+args[1], 'month');

      case 'getAddedMonth':
        return dayjs(value).month(args[1] ? (dayjs(value).get('month') + (+args[1])): 0).format(args[2])
    }
  }

}
