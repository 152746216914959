export const API_URLS = {
  // Other
  GET_HEADER(domainName) { return `domain/${domainName}`; },
  GET_IP: 'third_party/iplocation',
  // USer and Auth APIs
  LOGIN: 'auth/login',
  LOGOUT: 'users/logout',
  LOGIN_FACEBOOK: 'callback/facebook',
  LOGIN_GOOGLE: 'callback/google',
  LOGIN_GOOGLE_ONETAP: 'callback/googleonetap',
  LOGIN_OTP_VERIFY: 'verify/otp/token',
  USER_DETAILS_VERIFY: 'verify/details',
  LOGIN_OTP: 'auth/login/otpLogin',
  REGISTER: 'users',
  UPLOAD_PROFILE_PIC(userId) { return `users/${userId}/media/individual/profile`; },
  GET_USER_PROFILE: 'users/me',
  FORGOT_PASSWORD: 'auth/password/email',
  RESET_PASSWORD: 'auth/password/reset',
  PUSH_NOTIFICATIONS(userId) { return `users/${userId}/notifications/setting`; },
  GET_USER_ORDERS: 'users/me/orders',
  GET_USER_SUMMARY: 'users/me/orders/summary',
  AUTO_LOGIN: 'auth/autologin',
  PAYTM_LOGIN: 'callback/paytm',
  CHECK_USER_IS_VALID(emailId) { return `check/user/${emailId}`; },
  CHECK_EXISTING_EMAIL: 'admin/verify',
  REGISTER_USER() { return `user/register` },
  UPDATE_USER(id) { return `users/${id}/info`; },
  HF_DASHBOARD() { return `health/dashboard`; },
  ADD_CRITICAL_ILLNESS(entityId) { return `entity/${entityId}/infos`; },

  // Core APIs
  SESSION_CHECK: '/vars/auth.php',
  SYSTEM_EVENT_GTM: '/vars/system_event.php',
  CONTACT_CAMPAINGER: (campId) => `campaigns/${campId}/messages`,
  CONTRIBUTE_NOW: '/contribute/contribute.php',

  // Campaign
  GET_CAMPIGN_COUNT: 'campaign/count',
  GET_NGOS: 'entities/ngo/list',
  GET_CAUSES: 'causes/',
  GET_CAMPAIGNS: 'campaigns',
  GET_CAMPAIGN(draftId) { return `campaigns/${draftId}`; },
  GET_CAMPAIGN_DRAFT_DETAILS(entityId, draftId) { return `users/${entityId}/drafts/${draftId}`; },
  GET_CAUSE_BY_NGO_ID(ngoid) { return `entities/${ngoid}/causes`; },
  GET_MERGED_CAUSES: 'causes/mergedcauses?with=child&id=20,49',
  CREATE_NEW_CAMPAIGN(entityId) { return `users/${entityId}/campaigns`; },
  UPDATE_CAMPAIGN(draftId) { return `campaigns/${draftId}/drafts`; },
  UPLOAD_LEADERBOARD_IMAGE(draftId) { return `campaigns/${draftId}/media`; },
  DELETE_LEADERBOARD_IMAGE(draftId, mediaId) { return `campaigns/${draftId}/media/${mediaId}`; },
  // admin/entity/{entityId}/{entityType}/media/{id}
  GET_CAMPAIGN_PRODUCTS(campaigniD) { return `campaigns/${campaigniD}/products`; },

  UPLOAD_BENEFICIARY_IMAGE(draftId) { return `campaigns/${draftId}/upload/campaign/beneficiary`; },
  UPLOAD_EDITOR_IMAGE(draftId) { return `campaigns/${draftId}/upload/campaign/editoimage`; },
  UPLOAD_YOUTUBE_VIDEO(draftId) { return `campaigns/${draftId}/media`; },
  ADD_REWARD(draftId) { return `campaigns/${draftId}/rewards/`; },
  UPDATE_REWARD(draftId, rewardId) { return `campaigns/${draftId}/rewards/${rewardId}`; },
  GET_REWARD_BY_ID(draftId, rewardId) { return `campaigns/${draftId}/rewards/${rewardId}`; },
  GET_ALL_REWARDS(draftId) { return `campaigns/${draftId}/rewards/`; },
  DELETE_REWARD(draftId, rewardId) { return `campaigns/${draftId}/rewards/${rewardId}`; },
  DELETE_MEDIA(id, mediaId): string { return `campaigns/${id}/media/${mediaId}`; },
  PUBLISH_CAMPAIGN(draftId) { return `campaigns/${draftId}/publish`; },
  GET_LEAD: 'lead',
  DISEASE_LIST(searchTerm, searchField) { return `diseases?search=${searchTerm}&searchFields=${searchField}:like&orderBy=${searchField}`; },
  HOSPITAL_LIST(searchTerm, searchField) { return `hospitals?search=${searchTerm}&searchFields=${searchField}:like&orderBy=${searchField}`; },

  // Fundraiser
  GET_FUNDRAISER(customTag) { return `fundraisers/${customTag}`; },
  GET_DONORS(customTag) { return `fundraisers/${customTag}/donors`; },
  GET_FUNDRAISER_COMMENTS(customTag) { return `fundraisers/${customTag}/comments`; },
  GET_FUNDRAISER_SOCIAL_SHARE(campaignId) { return `fundraisers/${campaignId}/topshares`; },
  GET_SIMILAR_FUNDRAISER(campaignId) { return `campaigns/${campaignId}/similar`; },
  GET_FUNDRAISER_UPDATES(customTag) { return `fundraisers/${customTag}/updates`; },
  GET_DONOR_CARD(campaignId) { return `fundraisers/${campaignId}/donors`; },
  GET_SHARY_CARD(campaignId) { return `fundraisers/${campaignId}/supporter`; },
  POST_COMMENTS_CAMPAIGN(campaignId) { return `fundraisers/${campaignId}/comments`; },
  POST_UPDATE_LIKE(campaignId, updateId) { return `campaigns/${campaignId}/updates/${updateId}/like`; },
  POST_COMMENTS_UPDATE(campaignId, updateId) { return `fundraisers/${campaignId}/updates/${updateId}/comments`; },
  ASK_FOR_UPDATE(campaignId) { return `fundraisers/${campaignId}/askupdate`; },
  UPDATE_ANONYMOUS(entityId, orderId) { return `users/${entityId}/orders/${orderId}`; },
  GENERATE_UPI_LINKS: '/contribute/contribute_api.php',
  GENERATE_UPI_LINKS_RAZOR(campaignId) { return `campaigns/${campaignId}/qr`; },
  SUBMIT_ACKNOWLEDGE_FROM: 'payment',
  GET_EVENT(eventId) { return `events/${eventId}`; },
  GET_EVENT_CAUSES(eventId) { return `events/${eventId}/causes`; },
  GET_SUBSCRIPTIONS: 'subscriptions/count',
  CAMPAIGN_VISIT(campaignId, entityId) { return `campaigns/${campaignId}/entity/${entityId}/like`; },
  GET_DOMAIN() { return `getdomain`; },

  // Supporting Fundraiser
  GET_SUPPORTING_FRUNDRAISERS(campaignId) { return `fundraisers/${campaignId}/supporting_fundraisers`; },
  START_SUPPORTING_FRUNDRAISERS(campaignId) { return `fundraisers/${campaignId}/supporting_fundraisers`; },
  EDIT_SUPPORTING_FRUNDRAISERS(campaignId, supportingFrId) { return `fundraisers/${campaignId}/supporting_fundraisers/${supportingFrId}`; },
  CHECK_IF_SUPPORT_FR_CREATED(campaignId) { return `fundraisers/${campaignId}/supporting_fundraisers`; },

  // Home
  GET_BANNER() { return `banners/banner?with=mobile;desktop`; },
  GET_TRENDING_FR(currency: string, _ku?: string, qparams?) { return `campaigns?tags=trending&currency=${currency.toLowerCase()}&_ku=${_ku}${qparams}`; },
  GET_URGENT_FR() { return `banners/widgets?with=mobile;desktop&limit=1`; },
  GET_SUCCESS_STORIES() { return `banners/stories?with=mobile;desktop`; },
  GET_DRAFT_FR() { return `users/drafts?with=media&under_review=true`; },
  DISCARD_DRAFT() { return `users/drafts/discard`; },
  POST_LEAD_DATA() { return `lead`; },
  GET_TRENDING_STORY() { return `banner/story`; },

  GET_HOME_DATA: (entityId) => `entity/${entityId}/home`,
  GET_SIP_SUBSCRIPTIONS_DATA: () => `sip/subscriptions`,

  // Set currency
  SET_CURRENCY(cur: string): string { return `/vars/currency.php?cur=${cur.toUpperCase()}`; },

  // Browse
  WIDGET_DATA() { return `campaigns?id=`; },


  // Payment
  GET_PAYMENT_OPTIONS: `payment/options`,
  SEND_ORDER: `payment/send`,
  POST_AMOUNT_DETAILS(campaignId) { return `fundraisers/${campaignId}/cart`; },
  PLACE_ORDER(campaignId) { return `fundraisers/${campaignId}/order`; },
  GATEWAY_RESPONSE(orderId) { return `payment/response/${orderId}`; },
  CONTRIBUTE_DETAILS_PAYMENT_FORM(campaignId) { return `fundraisers/${campaignId}/contribute`; },
  GET_ORDER_DETIALS(campaignId, orderId) { return `fundraisers/${campaignId}/donors/${orderId}`; },
  VPA_VALIDATION: `verify/vpa`,
  VPA_TXN_STATUS: `verify/vpatxn`,
  GET_RECURRING_CONTRIBUTE_DETAILS: 'subscribe/contribute',
  GET_LATEST_SUBS: 'subscriptions/latest',
  SIMPL_ELIGIBILITY_CHECK: 'payment/eligibility',
  CREATE_ONDEMANG_SIP(id) { return `entity/${id}/subscriptions/payments` },

  // Insurance
  ADD_INSURANCE: 'insurance/create',
  GET_INSURANCE_PREMIUM: 'insurance/premium',
  PAYMENT_INSURANCE: 'insurance/pay',
  UPDATE_PAYMENT_INSURANCE: 'insurance/update',
  GET_INSURANCE_INFO(id) { return `insurance/${id}`; },

  // Portfolio Tickr
  PORTFOLIO_TICKR: 'show/tickr',

  // Donors for Tickr
  GET_TICKR_DATA(data) {
    return `fundraisers/${data.campaignId}/donors?with=donoravtar&currency=${data.currency}&orderBy=id&sortedBy=Desc&conversion=1&limit=${data.limit}&country_code=${data.country_code || ''}`;
  },

  // Success Stories Listing
  SUCCESS_STORIES: 'fundraisers/success-story',

  // User Subscription
  GET_USER_SUBSCRIPTION(entityId) { return `users/${entityId}/subscriptions/thankyou`; },
  INCREASE_PLEDGE(id) { return `subscriptions/${id}`; },
  AMOUNT_REDEEM: 'mbm/redeem',
  UPLOAD_MEDIA(id): string { return `campaigns/${id}/media`; },
  GET_HOSP_DISEASE_CITY(type): string { return `medical/types/${type}/treatment`; },
  GET_COST_HOSP_DISEASE_CITY(type): string { return `medical/types/${type}/treatment/cost`; },

  // COUPONS
  GET_COUPON_URL(campaignId): string { return `campaigns/${campaignId}/sip/coupons`; },
  GET_COA(orderId): string { return `orders/${orderId}/certificate`; },
  ACTIVATE_REWARD(entity_id): string { return `entity/${entity_id}/infos`; },
  VERIFY_PRIVATE_FUNDRAISER_PIN(campId): string { return `campaigns/${campId}/verify/pin`; },
  GET_COUPON(): string { return `entity/coupon`; },

  GET_SHORT_URL(): string { return `generate/short-url`; },

  IMPACT_PAGE_LEAD(entity_id) { return `entity/${entity_id}/impactpagelead`; },
  NEOKRED_API: (entityId) => `entity/${entityId}/credit/details`,
  GET_PRESSCLUB_DATA: 'pressclubs/list',

  // HEALTH FIRST
  GET_HEALTH_FIRST_PLANS: () => `health/dashboard`,
  POST_HEALTH_FIRST_CALCULATOR: () => `health/calculator`,
  PUT_HF_SEND_LEADS_INFOS: (entityId) => `entity/${entityId}/infos`,
  GET_HF_POLICY_DETAILS: (entityId) => `entity/${entityId}/policies/details`,
  GET_HOME_DOWNLOAD_COA: (orderId, param) => `orders/${orderId}/certificate/${param}`
};

